@font-face {
  font-family: "mont-regular";
  src: local("Mont-Regular"),
    url("./assets/fonts/Mont-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "mont-bold";
  src: local("Mont-Bold"),
    url("./assets/fonts/Mont-Bold.woff2") format("woff2");
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'mont-bold';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}